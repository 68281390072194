var config;
config = {
    templateBaseUrl: '/views/',
    useHtml5Mode: false,
    api_url: "https://api.lefresnoy.net/",
    rest_uri_v2: "https://api.lefresnoy.net/v2/",
    rest_uri: "https://api.lefresnoy.net/v1/",
    media_service: "https://media.lefresnoy.net/", 
    reset_password_uri: "https://api.lefresnoy.net/account/reset_password/",
    ame_rest_uri: "https://ame.lefresnoy.net/plugins/api_search/"
};
